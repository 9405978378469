@import '/src/theme';

.admin-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;
  padding: 50px !important;

  &--container {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    color: #fff;
  }
  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--wrapper {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center !important;
    }
  }
}

.update-device-modal {
  h5 {
    font-size: 20px;
  }

  p {
    font-size: 18px;
  }

  ul {
    color: #dc2f5c;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;

    li {
      color: #fff;
      list-style-type: none;
    }
  }

  .chakra-accordion__item {
    border: none;

    button {
      padding: 0;
    }

    .error-message {
      color: #dc2f5c;
    }
  }
}
