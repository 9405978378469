.error-comp-container {
  position: relative;
  margin: 30px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.error-icon {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
