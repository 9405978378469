@import '/src/theme';

.dashboard-page {
  margin-left: 370px;
  min-height: 100vh;
  background-color: $color__dark-gray;
  padding: 10px !important;

  &--no-devices {
    // background-color: #3a3a3c;
    border-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--container {
    background-color: #535355;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    position: relative;

    .add-device-btn {
      position: absolute;
      z-index: 10;
      top: -140px;
      right: 0;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);

      svg {
        padding: 5px;
        background-color: #db2f5c;
        border-radius: 50%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 80px;
    margin-left: 0;
  }

  h1 {
    color: #fff;
  }
}
