@import '/src/theme';

.plans-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;
  padding: 50px !important;

  &--container {
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
  }

  &--heading {
    margin: 0 0 20px 20px;
  }

  h1 {
    color: #fff;
  }
  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--wrapper {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center !important;
    }
  }

  .loading-container {
    max-width: 300px;
  }
}
