@import '/src/theme';

.no-device-message {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.store-link {
  color: #db2f5c;
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  transition: 0.4s all;
}

.store-link:hover {
  text-decoration: underline;
}
