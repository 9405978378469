.landing-page {
  max-width: 100%;
  background-color: #242424;
  padding-top: 50px;

  &--container {
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &--body {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    justify-self: center;
    width: 100%;

    h1 {
      font-size: 40px !important;
      margin-bottom: 20px;
      text-align: center;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 30px !important;
      text-align: center;

      span {
        color: #db2f5c;
        font-weight: 600;
        font-size: 30px;
      }
    }

    .feature-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3,
      p {
        font-size: 20px;
        text-align: center;
        max-width: 350px;
      }
    }
  }
}
