/* --------------------------------------------------------------
1.0 IMPORT
-------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');

@import '/src/theme';

/* --------------------------------------------------------------
2.0 GENERAL STYLE
-------------------------------------------------------------- */
html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}

h1 {
  font-size: 45px !important;
}

h2 {
  font-size: 40px !important;
}

h3 {
  font-size: 35px !important;
}

h4 {
  font-size: 25px !important;
}

p,
input,
label,
button,
td,
span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 22px;
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.4s all;
  opacity: 0;
}

/* --------------------------------------------------------------
3.0 MEDIA QUERIES
-------------------------------------------------------------- */

@media screen and (max-width: 576px) {
  h1 {
    font-size: 35px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 25px !important;
  }

  h4 {
    font-size: 20px !important;
  }
}

/* --------------------------------------------------------------
4.0 CHARKA OVERWRITE STYLE
-------------------------------------------------------------- */
.chakra-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.chakra-heading,
.chakra-input,
.chakra-alert,
.chakra-alert__title,
.chakra-alert__desc {
  font-family: 'Montserrat', sans-serif !important;
}

.chakra-table {
  font-variant-numeric: normal !important;
}

.chakra-tabs__tab {
  color: #ffffff64;
  background-color: #2e2e2f;

  h2 {
    font-size: 20px !important;
  }

  @media screen and (max-width: 576px) {
    h2 {
      font-size: 15px !important;
    }
  }
}

.chakra-tabs__tab:first-child {
  border-top-left-radius: 10px;
}

.chakra-tabs__tab:last-child {
  border-top-right-radius: 10px;
}

.chakra-tabs__tab[aria-selected='true'] {
  color: #fff !important;
  border-color: #dc2f5c !important;
}

.chakra-tabs__tab-panels {
  display: flex;
  justify-content: center;

  .chakra-tabs__tab-panel {
    background-color: #242424;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 20px;
    width: 100%;
  }
}

.chakra-modal__header {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px !important;
  font-weight: 700 !important;
}

.chakra-modal__body {
  position: relative;
}

.chakra-modal__content {
  border: 2px solid #fff;
  background-color: #3a3a3c !important;
  border-radius: 10px !important;
  padding: 30px;
  @media screen and (max-width: 576px) {
    padding: 30px 10px;
  }
}

.chakra-modal__footer {
  justify-content: center !important;
}

.chakra-select {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.chakra-popover__header,
.chakra-popover__body {
  font-family: 'Montserrat', sans-serif;
}

.chakra-select option {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
}

.chakra-select__icon-wrapper {
  svg {
    border-radius: 50%;
    background-color: #dc2f5c;
  }
}

.chakra-editable {
  display: flex;

  &__preview {
    min-width: 80px;
    border-radius: 5px;
    background: #393939 !important;
    margin-left: 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
  }
}

/* --------------------------------------------------------------
5.0 TAG CLASS COLOR
-------------------------------------------------------------- */
.order-status {
  font-size: 15px !important;
  font-weight: 500;
  padding: 3px 7px;
  border-radius: 15px;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  width: 110px;
  text-align: center;

  span::first-letter {
    text-transform: uppercase;
  }
}

.draft {
  background-color: #e49600 !important;
}

.confirmed {
  background-color: #1767df !important;
}

.closed {
  background-color: #0c9f24 !important;
}

.void {
  background-color: #d20000 !important;
}

.onhold {
  background-color: #7400d2 !important;
}

.default {
  background-color: #e4ce00 !important;
}

.pre-order {
  background-color: #dc2f5c !important;
  margin-left: 10px;
}

/* --------------------------------------------------------------
6.0 MISC
-------------------------------------------------------------- */
