.operating-menu {
  padding: 30px;
  background-color: #212020;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);

  p {
    font-size: 13px;
    font-style: italic;
  }
}

.led-channel-number {
  font-weight: 600;
  width: 30px;
  display: inline-block;
}

.switch-box {
  h1,
  h4 {
    min-width: 205px;
    margin-right: 15px;
  }
}
