.login-success-page {
  min-height: calc(100vh);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #242424;

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &--detail {
    margin-left: 20px;

    h1 {
      font-size: 40px;
      font-weight: 500;
      color: #dc2f5c;
    }

    p {
      font-size: 20px;
    }
  }
}
