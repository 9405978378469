@import '/src/theme';

.plan-card {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #1f1f1f;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 370px;

  @media screen and (max-width: 576px) {
    width: 330px;
  }

  &--info {
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--stats {
    margin-top: 20px;
    width: 100%;
    background-color: #3a3a3c;
    padding: 20px 32px;

    h4 {
      margin-bottom: 20px;
    }

    .stats-info {
      width: 100%;

      p {
        font-size: 15px;
        font-weight: 600;
        min-width: 180px;
      }

      .status {
        font-size: 15px;
      }
    }
  }
}
