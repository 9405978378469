@import '/src/theme';

.devices-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;
  padding: 50px !important;

  &--container {
    border-radius: 10px;
    min-height: calc(100vh - 100px) !important;
    display: flex;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--wrapper {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center !important;
    }
  }

  .device-link {
    height: fit-content;
  }

  .device-item {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    margin: 20px;
    width: 420px;

    @media screen and (max-width: 576px) {
      width: 320px;
    }

    p {
      display: flex;
      align-items: center;
      margin-top: 5px;
      z-index: 1;

      svg {
        margin-right: 5px;
      }
    }

    .device-info {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: #3a3a3c;

      h3 {
        z-index: 1;
        font-size: 25px;
        @media screen and (max-width: 576px) {
          font-size: 20px;
        }
      }

      p {
        font-size: 18px;
      }
    }

    .device-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 40px 0;
      }
    }
  }

  .order-item:hover {
    .overlay {
      opacity: 1;
    }
  }
}
