.single-attribute-view {
  position: relative;
  padding: 12px;
  background-color: #3a3a3c;
  animation: zoomIn 0.2s ease-in-out;

  .modbus-rtu-attribute-name {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    text-align: center;

    span {
      background-color: #dc2f5c;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .attribute-carousel {
    width: 100%;
    height: 100%;
    padding: 15px;

    .carousel {
      position: relative !important;
    }
  }

  .modbus-rtu-attribute-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .modbus-rtu-attribute-data {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-top: 10px;

    h1 {
      font-size: 25px !important;
    }

    h3 {
      font-size: 35px !important;
    }
  }

  .zoom-btn {
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 20px;
  }
}
