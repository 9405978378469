.error-page {
  min-height: calc(100vh);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #242424;

  @media screen and (max-width: 991px) {
    margin-left: 0;
  }

  .error-detail {
    h3,
    h4 {
      font-weight: 600;
    }

    h4 {
      color: #dc2f5c;
    }

    p {
      max-width: 80%;
      text-align: center;
    }
  }
}

.page-404 {
  min-height: calc(100vh);
  max-width: 100%;
  margin-left: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #242424;

  @media screen and (max-width: 991px) {
    margin-left: 0;
  }

  .container-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3a3a3c;
    border-radius: 10px;
    min-height: calc(100vh - 40px);
  }
}

.error-icon {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3a3a3c;
  border-radius: 10px;
  min-height: calc(100vh - 40px);
}

.error-details {
  h3,
  p {
    text-align: center;
  }
}

.btn-group {
  border-radius: 10px;
}
