.device-data {
  color: #56d5dc !important;
  font-family: 'Roboto Mono', sans-serif !important;
  min-width: 140px;
}

.channel-row {
  cursor: pointer;
}

.channel-row:hover {
  background-color: rgba(220, 47, 93, 0.2);
  transition: 0.3s all;
}

.staled-data {
  color: #56d5dc64 !important;
}

.aux-device-modal {
  td {
    font-size: 14px;
    padding: 15px 20px;
  }
}

.aux-table {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  th,
  td {
    font-size: 18px !important;
  }
}

.aux-table-hide {
  display: none !important;
}

.table-pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pagination-previous,
  .pagination-next {
    width: 30px;
    height: 30px;
    margin: 0 20px;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chakra-stack > li > button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    color: #fff;
  }

  .chakra-stack > li > button[aria-current='true'] {
    background-color: #dc2f5c;
  }
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.single-channel-view {
  position: relative;
  padding: 12px;
  background-color: #3a3a3c;
  animation: zoomIn 0.2s ease-in-out;

  .channel-name {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    text-align: center;

    span {
      background-color: #dc2f5c;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .channel-wrapper {
    .channel-carousel {
      margin-top: 10px;

      .carousel {
        position: relative !important;
      }
    }
  }

  .channel-item {
    background-color: #535355;
    padding: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

    h1 {
      font-size: 20px !important;
    }

    h3 {
      font-size: 25px !important;
    }
  }

  .zoom-btn {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 10px;
  }
}

.aux-device-id {
  color: #dc2f5c;
}
