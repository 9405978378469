.reg-code-process {
  .code-status {
    color: #059b02;
    font-size: 18px;
    font-weight: 600;
  }

  .error {
    color: #ff0f0f;
  }

  .error-info {
    font-size: 14px;

    a {
      font-weight: 600;
    }
  }

  .reg-code-input {
    input {
      width: 50px;
      height: 60px;
      margin-right: 5px;
      text-align: center;
      font-size: 30px;
      padding-bottom: 5px;
      border: 1px solid #fff;
    }
  }

  .reg-code-input-disabled {
    input {
      background-color: #242424;
      border: 1px solid #c4c4c428;
    }
  }
}
