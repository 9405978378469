@mixin custom-toggle-branding(
  $custom-toggle-off-color: #777,
  $custom-toggle-inactive-text: rgba(white, 0.5),
  $custom-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type='checkbox'] {
    &[disabled] ~ label {
      color: #fff;
    }

    &:focus ~ label,
    &:hover ~ label {
      .custom-toggle__switch {
        background-color: $custom-toggle-off-color;
        border-radius: 7px !important;
        cursor: pointer;

        &::after {
          background-color: #535355;
          color: #fff;
          font-weight: 600;
        }
      }
    }

    &:hover ~ label {
      color: darken($custom-toggle-off-color, 5%);
    }

    &:checked {
      ~ label {
        .custom-toggle__switch {
          &::after {
            background-color: #db2f5c;
            color: #fff;
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .custom-toggle__switch {
            &::after {
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  label {
    .custom-toggle__label-text {
      flex: 1;
    }

    .custom-toggle__switch {
      transition: background-color 0.3s $custom-toggle-transition;
      background-color: #393939 !important;

      &::before {
        color: $custom-toggle-inactive-text;
      }

      &::after {
        -webkit-transition: -webkit-transform 0.3s $custom-toggle-transition;
        transition: transform 0.3s $custom-toggle-transition;
        color: $custom-toggle-off-color;
        background-color: #535355;
      }
    }
  }
}

@mixin custom-toggle-appearance(
  $custom-toggle-width: 180px,
  $custom-toggle-height: 36px,
  $custom-toggle-border-radius: 4px,
  $custom-toggle-offset: 2px,
  $custom-toggle-label-font-size: 14px,
  $custom-toggle-switch-font-size: 12px,
  $custom-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $custom-toggle-switch-width: calc($custom-toggle-width/2);

  input[type='checkbox'] {
    &:focus ~ label,
    &:hover ~ label {
      .custom-toggle__switch {
        &::after {
          box-shadow: $custom-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .custom-toggle__switch {
          &::after {
            transform: translate3d(
              $custom-toggle-width -
              ($custom-toggle-switch-width + $custom-toggle-offset),
              0,
              0
            );
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .custom-toggle__switch {
            &::after {
              box-shadow: $custom-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $custom-toggle-label-font-size;

    .custom-toggle__switch {
      height: $custom-toggle-height;
      flex: 0 0 $custom-toggle-width;

      &::before {
        left: calc($custom-toggle-width/2);
        font-size: $custom-toggle-switch-font-size;
        line-height: $custom-toggle-height;
        width: calc($custom-toggle-width/2);
        padding: 0 12px;
      }

      &::after {
        top: $custom-toggle-offset;
        left: $custom-toggle-offset;
        width: calc($custom-toggle-switch-width - $custom-toggle-offset);
        line-height: calc($custom-toggle-height - ($custom-toggle-offset * 2));
        font-size: $custom-toggle-switch-font-size;
      }

      &:hover {
        &::after {
          box-shadow: $custom-toggle-shadow;
        }
      }
    }
  }
}

.custom-toggle {
  position: relative;
  width: 180px;
  border-radius: 7px;
  margin: 5px 0;

  * *::before,
  *::after {
    box-sizing: border-box;
  }

  overflow: hidden;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;

      .custom-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .custom-toggle__switch {
          &::before {
            content: attr(data-unchecked);
            left: 0;
          }

          &::after {
            content: attr(data-checked);
          }
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .custom-toggle__switch {
      position: relative;

      &::before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
        background-color: #393939;
      }

      &::after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        color: #fff !important;
        font-weight: 600;
        border-radius: 7px !important;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .custom-toggle class
  @include custom-toggle-branding;
  @include custom-toggle-appearance;

  // Or re-brand the toggle for different applications
  &.modal-switch {
    @include custom-toggle-branding(
      #242424,
      // Off state color
      rgba(white, 0.6),
      // Off state text color
      ease-in-out // Transition timing function
    );
  }
}
