@import '/src/theme';

.device-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;

  &--container {
    border-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--container-center {
    border-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--plans {
    .device-container {
      margin: 0 !important;
      width: 50%;

      @media screen and (max-width: 1200px) {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--details {
    border-bottom: 2px solid #000;

    .device-container {
      margin: 0 !important;
      width: 50%;
      @media screen and (max-width: 1200px) {
        width: 450px;
      }
    }
  }

  &--details:last-child {
    border-bottom: none;
  }

  .device-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 40px;
    @media screen and (max-width: 991px) {
      width: 550px;
      margin: 0 20px;
    }
    @media screen and (max-width: 571px) {
      width: 350px;
      margin: 0 10px;
      padding: 20px;
    }

    .no-plan {
      text-align: center;
      line-height: 45px;
      margin-bottom: 20px;
    }

    .device-activation-date {
      margin-top: 20px;

      span {
        font-weight: 700;
      }
    }

    .plan-heading {
      color: #9f9f9f;
      font-size: 32px;
      font-weight: 700 !important;
    }
  }
}

.delete-device-modal {
  .device-id,
  span {
    font-weight: 500 !important;
  }

  span {
    color: #dc2f5c;
  }
}
