@import '/src/theme';

.plan-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;

  &--container {
    border-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--details {
    border-bottom: 2px solid #000;

    .plan-container {
      margin: 0 !important;
      width: 50%;

      .plan-name {
        font-size: 61px !important;
        margin-bottom: 40px;
      }
      @media screen and (max-width: 1200px) {
        width: 450px;
      }

      .plan-start-date {
        margin-top: 40px;
        font-size: 30px !important;
        font-weight: 400;
      }
    }
  }

  &--details:last-child {
    border-bottom: none;
  }

  .plan-container {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 40px;
    @media screen and (max-width: 991px) {
      width: 550px;
      margin: 0 20px;
    }
    @media screen and (max-width: 571px) {
      width: 350px;
      margin: 0 10px;
      padding: 20px;
    }

    h4 {
      margin-bottom: 7px;

      span {
        color: #db2f5c;
      }
    }

    .device-id {
      margin-bottom: 40px;
    }

    .device-activation-date {
      font-weight: 700;
    }

    .plan-heading {
      color: #9f9f9f;
      font-size: 32px;
      font-weight: 700 !important;
    }

    .plan-usage {
      font-weight: 600;
      font-size: 18px;
    }

    .plan-usage-bar {
      div[role='progressbar'] {
        background-color: #db2f5c !important;
      }
    }

    .device-list {
      background-color: #1e1e1e;
      border-radius: 10px;
      padding: 5px;

      .device-info {
        background-color: #2b2b2b;
        border-radius: 10px;
        padding: 10px;

        h4 {
          font-size: 16px !important;
        }

        .device-id {
          font-size: 16px !important;
          margin: 0;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .device-info:hover {
        background-color: #40404084;
        transition: 0.3s all;
      }
    }
  }
}
