@import '/src/theme';

.order-page {
  margin-left: 370px;
  min-height: 100vh;
  padding: 10px !important;
  background-color: $color__dark-gray;

  &--container {
    padding: 0;
    border-radius: 10px;
    min-height: calc(100vh - 20px) !important;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
  }

  td {
    padding-left: 0 !important;
    border: none;
  }
}

.shipping-heading {
  font-size: 20px;
  margin-bottom: 20px;
}

.order-container {
  padding: 40px 25px 25px 25px;
  border-radius: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 576px) {
    padding: 10px;
  }

  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .order-number {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 39px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @media screen and (max-width: 576px) {
      font-size: 20px;
      line-height: 21px;
    }

    .order-status {
      margin-left: 20px;
      margin-top: 0;
      display: flex;
      justify-content: center;
      width: 120px;

      span {
        font-weight: 600;
        font-size: 15px !important;
      }
    }

    .order-back-btn {
      cursor: pointer;
    }
  }

  .order-information {
    margin-bottom: 20px;

    .order-details {
      border: none;

      p {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 15px;
        @media screen and (max-width: 576px) {
          font-size: 14px;
          margin-bottom: 12px;
        }

        svg {
          margin-right: 10px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }

      .order-info-heading {
        font-weight: 600;
        width: 110px;
      }
    }
  }
}

.item-summary {
  margin-top: 50px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 5px;
  @media screen and (max-width: 998px) {
    display: none !important;
  }

  .order-quantity {
    padding-left: 40px !important;
  }

  .item-name {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .product-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 25px !important;
    line-height: 30px;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  .product-modal {
    font-size: 14px;
  }

  .sku {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .sub-total {
    padding-top: 20px !important;
    font-size: 15px;
  }

  .sub-total,
  .shipping,
  .tax {
    font-size: 17px;
  }

  .tax {
    padding-bottom: 20px !important;
  }

  .order-total {
    padding-top: 20px !important;
    margin-left: auto;
    font-size: 25px;
    font-weight: 400;
  }
}

.item-summary-mobile {
  display: none;
  @media screen and (max-width: 998px) {
    display: flex;
    flex-direction: column;
  }

  .order-prices {
    p {
      padding: 10px 0;
    }
  }

  .quantity,
  .price {
    font-weight: 600;
  }

  .order-total {
    h2 {
      margin-left: auto;
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.order-details {
  border-radius: 20px;
  border: 1px solid rgba(211, 211, 211, 0.42);

  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.extra-row {
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.border-top-light {
  border-top: 1px solid #1a202c;
}

.border-top-dark {
  border-top: 1px solid #fff;
}

.item-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding-left: 0 !important;
}

.order-process {
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
