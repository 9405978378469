.custom-btn {
  min-width: 200px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  transition: 0.3s all;
  font-size: 20px;
  font-weight: 500;
  padding: 5px 0;

  &--red {
    background-color: #db2f5d;
    color: #fff;
  }

  &--red:hover {
    background-color: #db2f5dcc;
  }
}

.custom-disabled {
  cursor: not-allowed !important;
  user-select: none !important;
  background-color: #5a5a5a;
  opacity: 0.5;
}

.custom-disabled:hover {
  background-color: #5a5a5a !important;
}
