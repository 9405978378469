@import '/src/theme';

.sidebar {
  width: 370px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: 0.4s all;
  z-index: 30;
  background-color: $color__gray;
  @media screen and (max-width: 991px) {
    display: none;
  }

  .logo-container {
    margin-top: 60px;
    padding-left: 15px;
  }

  .logo {
    max-width: 300px;
  }

  .bottom-section {
    width: 100%;

    .app-version {
      text-align: right;
      margin-right: 15px;
    }
  }
}

.logout {
  font-size: 15px;
  background-color: $color__dark-gray !important;
  border-radius: 7px;
  color: #fff;
}

.profile-picture {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;
  margin-top: 50px;
  max-width: 400px;

  .chakra-avatar {
    margin: 10px 10px 10px 0;
    border: 3px solid #efefef;
    width: 70px;
    height: 70px;
    color: #fff;
    background-color: $color__dark-gray;
  }

  h3 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #efefef;
  }

  h4 {
    font-size: 18px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #efefef;
  }

  .account-selector {
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
}

.main-menu {
  margin-top: 30px;

  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .option {
      width: 100%;
      font-size: 30px;
      line-height: 30px;
      color: #efefef;
      text-align: center;
      padding: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      display: flex;
      align-items: center;
      transition: 0.3s all;
      border-right: 2px solid transparent;

      svg {
        margin-right: 20px;
        transition: 0.3s all;
      }
    }

    .option:hover {
      background-color: $color__red;
    }

    .active {
      background-color: $color__red;
      user-select: none;
      pointer-events: none;
    }
  }
}

.bottom-section {
  padding-left: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  p {
    margin-left: 10px;
    font-size: 15px;
    line-height: 18px;
    color: #efefef;
  }

  .help-section {
    color: #efefef;

    p {
      font-size: 11px;
      line-height: 13px;
    }
  }
  @media screen and (max-width: 576px) {
    padding-left: 0;
  }
}

.mobile-menu {
  background-color: $color__gray;
  position: fixed;
  transition: 0.4s all;
  z-index: 30;
  width: 100%;

  @media screen and (min-width: 992px) {
    display: none;
  }

  &--top {
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 3px 15px 5px rgba(0, 0, 0, 0.5);

    .logo-container {
      width: 200px;
    }

    .logo {
      max-width: 300px;
    }

    .nav-icon {
      margin: 16px;
      width: 40px;
      cursor: pointer;
    }

    .nav-active {
      .nav-icon::before {
        transform: translateY(12px) rotate(135deg);
      }

      .nav-icon::after {
        transform: translateY(-12px) rotate(-135deg);
      }

      .nav-icon div {
        transform: scale(0);
      }
    }

    .nav-icon::after,
    .nav-icon::before,
    .nav-icon div {
      background-color: #fff;
      border-radius: 3px;
      content: '';
      display: block;
      height: 5px;
      margin: 7px 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &--body {
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 10px;

    .profile-picture {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-top: 30px;
      padding-left: 0;

      .chakra-avatar {
        margin-right: 15px;
        border: 3px solid #efefef;
        width: 70px;
        height: 70px;
      }
    }

    .main-menu {
      width: 100%;

      .options {
        width: 100%;

        .option {
          width: 300px;

          p {
            width: 100%;
          }
        }
      }
    }
  }

  .bottom-section {
    margin-bottom: auto;

    .app-version {
      text-align: center;
      margin: 20px 0 0 0;
    }
  }
}
