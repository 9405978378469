.dashboard-stats {
  margin: 0 0 40px 0;
  position: relative;

  &--item {
    padding: 35px 15px 55px 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #3a3a3c;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    flex: 1;

    h1 {
      font-size: 48px;
    }

    h3 {
      font-size: 24px !important;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-weight: 500 !important;
    }

    .staled-data {
      color: #56d5dc64;
      font-family: 'Roboto Mono', sans-serif;
    }

    .sys-data {
      color: #56d5dc;
      font-family: 'Roboto Mono', sans-serif;
    }

    .sys-chart-btn {
      margin-top: 10px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      cursor: pointer;
    }

    .sys-chart-btn:hover {
      opacity: 0.8;
      transition: 0.3s all;
    }

    span {
      font-weight: 500;
    }

    .memory-usage-percentage {
      font-size: 24px;
    }
    @keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
      }
    }

    .fan-icon {
      animation: rotating 2s linear infinite;
    }
  }

  &--setting {
    cursor: pointer;
    position: absolute;
    bottom: -30px;
    right: 0;
    z-index: 10;
    width: 50px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #3a3a3c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  // &--item:first-child {
  //   border-bottom-left-radius: 20px;
  //   border-top-left-radius: 20px;
  // }

  // &--item:last-child {
  //   border-bottom-right-radius: 20px;
  //   border-top-right-radius: 20px;
  // }

  .cpu-temp-setting {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}

.dashboard-setting-modal {
  background-color: #242424;
  border-radius: 10px;

  h1 {
    margin-right: 10px;
    font-size: 25px;
  }
}
