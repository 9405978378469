.custom-select {
  margin-top: 10px;
  width: 250px;
  border-radius: 5px;
  background-color: #393939;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  & > div {
    background-color: #393939;
    border: none;
  }
}

.invalid-custom-select {
  border: 2px solid #fc8181;
}

.invalid-channels {
  border: 2px solid #fc8181;
  border-radius: 7px;
}

.fc-tooltip {
  position: relative;
  cursor: pointer;

  .fc-tooltip-box {
    visibility: hidden;
    width: 130px;
    position: absolute;
    top: -30px;
    left: 50px;
    margin: auto;

    p {
      font-style: normal;
      margin: 3px 0;
    }
  }
}

.fc-tooltip:hover {
  .fc-tooltip-box {
    transition: 0.3s all;
    visibility: visible;
  }
}

.tab-details {
  font-style: normal !important;
}

.field-heading {
  font-style: italic;
}

.mapping-container {
  margin-top: 20px;

  thead {
    background-color: #3a3a3c;
    top: 0;
    z-index: 2;
    position: sticky !important;

    tr > th {
      font-size: 15px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
    }
  }

  td {
    font-size: 12px;
    font-weight: 500;
  }

  tbody {
    width: 100%;
  }

  .mapping-list {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 280px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.attr-text-value {
  font-family: 'Roboto Mono', sans-serif;
  text-transform: uppercase;
  margin-right: 5px;
}
