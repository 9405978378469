.dac-type {
  flex: 1;

  &--data {
    @media screen and (max-width: 576px) {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 10px;
    }
  }

  &--channel {
    width: 100%;

    h2 {
      line-height: 50px;
      font-size: 22px !important;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      @media screen and (max-width: 576px) {
        line-height: unset;
      }
    }

    .channel-number {
      min-width: 100px;
      margin-left: 20px;
      display: flex;
      @media screen and (max-width: 576px) {
        margin-left: 0;
        min-width: 85px;
      }
    }
    @media screen and (max-width: 576px) {
      width: unset;
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}

.dac-status {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media screen and (max-width: 576px) {
    margin-left: unset;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  svg {
    margin: 0 5px;
  }

  .cloud-status {
    width: 40px;
    padding-right: 10px;
    border-right: 2px solid #fff6;

    @media screen and (max-width: 576px) {
      border: none;
      padding-right: 0;
      width: 25px;
    }
  }
}

.dac-value {
  text-align: right;
  font-weight: 500;
  color: #56d5dc64;
  font-family: 'Roboto Mono', sans-serif;
  margin-left: 10px;
  min-width: 110px;
  @media screen and (max-width: 576px) {
    text-align: left !important;
    min-width: 0;
  }
}

.dac-value-updated {
  text-align: right;
  margin-left: 10px;
  font-weight: 500;
  color: #56d5dc;
  min-width: 110px;
  font-family: 'Roboto Mono', sans-serif;
  @media screen and (max-width: 576px) {
    text-align: left !important;
    min-width: 0;
  }
}
