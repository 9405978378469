.thermocouple-view {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.cloud-data-explaination {
  font-size: 10px;
}

.tc-data {
  color: #56d5dc !important;
  font-family: 'Roboto Mono', sans-serif !important;
}
