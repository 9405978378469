.migration-page {
  min-height: calc(100vh);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 370px;
  background-color: #242424;
  padding-left: 6rem;
  padding-right: 6rem;
  @media screen and (max-width: 991px) {
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .migration-container {
    h3 {
      font-size: 30px;
    }

    p {
      color: #a0a0a0;
    }

    .accounts-table {
      th {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 15px;
        text-decoration: none !important;
        color: #fff;
      }

      th,
      td {
        padding-left: 0;
      }
    }
  }
}
