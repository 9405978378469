.adc-modal {
  &--box {
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;

    .type {
      margin-right: 20px;
      width: 100px;
    }

    h3 {
      font-size: 22px;
      margin-right: 10px;
    }

    h4 {
      font-size: 18px;
    }

    p {
      font-size: 13px;
      font-style: italic;

      span {
        font-style: normal;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .diff-checkbox {
      .chakra-checkbox__label {
        background-color: #393939;
        padding: 3px 25px;
        width: 60px;
        text-align: center;
        border-radius: 5px;
      }

      .chakra-checkbox__input,
      .chakra-checkbox__control {
        display: none;
      }

      .chakra-checkbox__label[data-checked] {
        background-color: #dc2f5c;
      }
    }

    .data-rate {
      font-weight: 400 !important;
    }
  }

  &--title {
    margin-right: 10px;
  }

  &--item {
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 13px;
      font-style: italic;

      span {
        font-style: normal;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  &--item-details {
    border-radius: 10px;
    padding: 20px;
    background: #1c1c1c;
  }

  &--protocol {
    background-color: #242424;
    padding: 20px;
    border-radius: 10px;

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 13px;
      font-style: italic;
    }
  }

  &--dashboard {
    position: relative;
  }
}

.port-number {
  width: 40px;
  height: 40px;
  background-color: #3a3a3c;
  border-radius: 10px;
  border: 1px solid #ffffff80;
  margin: 0 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #ffffff80;
  text-align: center;
  line-height: 40px;
}
