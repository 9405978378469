@import '/src/theme';

.orders-page {
  margin-left: 370px;
  background-color: $color__dark-gray;
  min-height: 100vh;
  padding: 50px !important;

  &--container {
    border-radius: 10px;
    min-height: calc(100vh - 100px) !important;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 10px !important;
  }

  &--wrapper {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center !important;
    }
  }

  td {
    padding-left: 24px !important;
  }

  .order-link {
    height: fit-content;
  }

  .order-item {
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
    width: 450px;
    background-color: #3a3a3c;

    @media screen and (max-width: 576px) {
      width: 350px;
    }

    h2 {
      z-index: 1;
      margin-right: 10px;
      @media screen and (max-width: 576px) {
        font-size: 20px;
      }
    }

    p {
      display: flex;
      font-size: 20px;
      align-items: center;
      margin-bottom: 5px;
      z-index: 1;

      svg {
        margin-right: 5px;
      }
    }

    .items-container {
      margin: auto;
      width: 100%;
    }

    .product-image {
      background: #c0c0c0;
      position: relative;
      margin-top: 10px;
      border-radius: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      img {
        max-width: 200px;
        min-height: 250px;
      }
    }

    .order-info {
      background: #242424;
      padding: 20px 20px 10px 20px;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .product-name {
        font-size: 24px !important;
        font-weight: 500 !important;
        line-height: 20px;
        white-space: pre-wrap;
        text-align: left;
      }

      .quantity {
        color: #bababa;
        font-size: 16px;
        margin-top: 5px;
      }
    }

    .sub-total {
      margin-left: auto;
      font-size: 26px;
      margin-top: 20px;
    }
  }

  .order-item:hover {
    .overlay {
      opacity: 1;
    }
  }
}
