@import '/src/theme';

.add-device-page {
  margin-left: 370px;
  min-height: 100vh;
  background-color: $color__dark-gray;
  padding: 50px;
  @media screen and (max-width: 991px) {
    margin-top: 100px;
    margin-left: 0;
    padding: 20px;
  }

  .page-header {
    position: relative;

    .add-device-back-btn {
      cursor: pointer;
      position: absolute;
      left: -50px;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .add-device-back-btn:hover {
      opacity: 0.8;
      transition: 0.3s all;
    }
  }

  .page-description {
    font-size: 22px;
    @media screen and (max-width: 576px) {
      font-size: 18px;
    }
  }

  .summary-container {
    p {
      font-size: 22px;
    }
  }

  .add-device-step-heading {
    margin: 20px 0;
  }

  .add-device-form-options {
    margin: 10px 0;
    background-color: #3a3a3c;
    padding: 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s all;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &--textbox {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .device-id {
        color: #db2f5c;
        margin-bottom: 20px;

        span {
          color: #fff;
        }
      }
    }

    &--detail {
      color: #db2f5c;
      margin-bottom: 10px;
    }
  }

  .add-device-form-options:hover {
    background-color: #535355;
  }

  .store-link {
    color: #db2f5c;
  }

  .last-option {
    margin-bottom: 40px;
  }

  .disabled {
    pointer-events: none;
    user-select: none;
    position: relative;

    .overlay {
      background-color: #2d2d2dd8;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .product-info-container {
    margin: 40px 0;
    background-color: #3a3a3c;
    padding: 40px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 576px) {
      padding: 20px;

      h1 {
        font-size: 25px !important;
      }
    }

    .product-id {
      color: #db2f5c;
      font-size: 20px;
      margin-bottom: 30px;
      @media screen and (max-width: 991px) {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 10;
    }
  }

  .product-info-container:hover {
    background-color: #535355;
  }

  .active {
    background-color: #535355;
  }

  .page-divider {
    border-top: 2px solid #fff;
    margin: 40px 0;
  }
}

.step-header {
  color: #db2f5c;
}

.provisioning-step {
  font-size: 25px;
}

.provisioning-completed {
  font-size: 20px !important;
  margin-top: 50px;
}

.provisioning-status {
  span {
    color: #db2f5c;
  }
}
