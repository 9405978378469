.adc-type {
  flex: 1;

  &--data {
    @media screen and (max-width: 576px) {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 10px;
    }
  }

  &--channel {
    width: 100%;

    h2 {
      line-height: 50px;
      font-size: 22px !important;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      @media screen and (max-width: 576px) {
        line-height: unset;
      }
    }

    .channel-number {
      min-width: 80px;
      display: flex;
      margin-left: 30px;
      @media screen and (max-width: 576px) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 576px) {
      flex-direction: column !important;
      width: unset;
    }
  }
}

.adc-value {
  margin-left: 10px;
  font-weight: 500;
  color: #56d5dc64;
  font-family: 'Roboto Mono', sans-serif;
  min-width: 110px;
  text-align: right;
  @media screen and (max-width: 576px) {
    text-align: left !important;
    min-width: 0;
  }
}

.adc-value-updated {
  margin-left: 10px;
  min-width: 110px;
  font-weight: 500;
  color: #56d5dc;
  font-family: 'Roboto Mono', sans-serif;
  text-align: right;
  @media screen and (max-width: 576px) {
    text-align: left !important;
    min-width: 0;
  }
}

.graph-img {
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.adc-status {
  display: flex;
  align-items: center;
  margin-left: auto;

  @media screen and (max-width: 576px) {
    margin-left: unset;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  svg {
    margin: 0 5px;
  }

  .cloud-status {
    width: 40px;
    padding-right: 10px;
    border-right: 2px solid #fff6;

    @media screen and (max-width: 576px) {
      border: none;
      padding-right: 0;
      width: 25px;
    }
  }
}

.input-mode-diff {
  width: 86px;
}

.input-mode-rtd {
  margin-right: 24px;
}
