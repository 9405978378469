.modbus-rtu-readblock {
  font-size: 24px;
  font-weight: 600;
  color: #dc2f5c;
  transition: 0.4s all;
}

.active-readblock {
  background-color: #cf2351 !important;

  .modbus-rtu-readblock {
    color: #fff;
  }
}

.modbus-rtu-attribute {
  cursor: pointer;
  margin: 5px 0;
  padding-left: 5px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  transition: 0.4s all;
  display: flex;
  align-items: center;
}

.modbus-rtu-attribute:hover {
  color: #56d5dc;
}

.chakra-accordion__button:hover {
  background-color: #2d3748;
}

.chakra-accordion__button[aria-expanded='true'] {
  background-color: #393939;
}

.chakra-accordion__button[aria-expanded='true'] + .chakra-collapse {
  display: block !important;
}

.active-attribute {
  position: relative;
  color: #fff;
  background-color: #dc2f5c;
}

.active-format {
  background-color: #dc2f5c;
}

div[role='tooltip'] {
  font-family: 'Montserrat', sans-serif !important;
}

.item_name {
  color: #dc2f5c;
  font-size: 16px;
}
