.dashboard-view {
  position: relative;
  margin-bottom: 30px;

  .dashboard-module {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #3a3a3c;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--heading {
      padding: 25px 0 25px 31px;
      border-bottom: 1px solid #fff6;
      display: flex;
      align-items: center;

      h2 {
        font-size: 30px;
      }

      @media screen and (max-width: 576px) {
        padding: 15px;
      }
    }

    &--content-disabled {
      position: absolute;
      background-color: #3a3a3ce8;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        padding: 20px;
        text-align: center;
        @media screen and (max-width: 576px) {
          padding: 15px;
          font-size: 18px;
        }

        a {
          color: #db2f5c;
        }
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      min-height: 300px;

      .thermo-opts {
        display: flex;

        div {
          text-align: center;
          line-height: 40px;
          width: 40px;
          height: 40px;
          margin: 0 5px;
          font-size: 20px;
          background-color: #535355;
          border-radius: 5px;
          cursor: pointer;
        }

        .thermo-opt-active {
          border: 1px solid #db2f5c;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 500;
        max-width: 400px;
        margin: 40px 0;
        text-align: center;
      }

      .relay-state {
        font-weight: 500 !important;
      }

      .grey-out {
        opacity: 0.6;
        font-weight: 500 !important;
      }
    }

    &--footer {
      width: 100%;
      justify-content: flex-end;
      align-items: flex-end;

      .thermal-probe-type {
        font-size: 20px;
        font-weight: 600;
        padding-left: 19px;
        @media screen and (max-width: 576px) {
          padding-left: unset;
        }
      }
    }

    &--table {
      padding: 15px;
      @media screen and (max-width: 576px) {
        padding: 10px;
      }
    }

    &--adc,
    &--dac {
      padding: 15px 15px 15px 35px;
      max-width: 100%;
      @media screen and (max-width: 576px) {
        padding: 0 0 0 10px;
      }
    }

    &--na {
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    td,
    th {
      border: none;
      font-weight: 600;
      font-size: 22px;
      font-family: 'Montserrat', sans-serif;
      @media screen and (max-width: 576px) {
        font-size: 18px;
        padding-left: 0;
      }
    }

    .led-status {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .led-function {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        margin: 0 5px;
      }

      .cloud-status {
        width: 40px;
        padding-right: 10px;
        border-right: 2px solid #fff6;
        @media screen and (max-width: 576px) {
          border: none;
          padding-right: 0;
        }
      }
    }

    .active {
      background-color: #fff500;
    }

    .paused {
      background-color: #fff70048 !important;
    }

    .disabled {
      background-color: #6f6f6f;
    }

    .card-holder-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 35px;
      min-height: 350px;

      h2 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        max-width: 550px;
        text-align: center;
      }

      .italic {
        margin-top: 20px;
        font-style: italic;
      }
    }
  }
}

.channel-orders {
  min-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  @media screen and (max-width: 576px) {
    display: none !important;
  }

  .channel {
    width: 100%;
    height: 50px;
    border: 1px solid #ffffff80 !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 22px;
    background-color: #565657;
    @media screen and (max-width: 576px) {
      display: none;
      width: unset;
    }
  }

  .channel-deactivated {
    background-color: #3c3c3e;
  }

  .channel:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .channel:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.channel-mobile {
  width: 30px;
  height: 30px;
  border: 1px solid #ffffff80 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 22px;
  background-color: #565657;
  margin-right: 20px;
  @media screen and (min-width: 577px) {
    display: none;
  }
}

.channel-mobile-deactivated {
  background-color: #3c3c3e !important;
}

.sdk-enabled {
  color: #db2f5c;
  font-size: 16px !important;
  margin-bottom: 10px;
}

.modal-disabled {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 95%;
  height: 100%;
  background-color: #2626263a;
  border-radius: 10px;
}

.dashboard-disabled {
  position: relative;
  margin: 30px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.dashboard-devices-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;

  .dashboard-device {
    flex: 0 0 auto;
    max-width: 450px;
    min-width: 300px;
    padding: 10px 30px;
    background-color: #3a3a3c;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s all;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    img {
      margin: 10px 10px 10px 0;
    }

    .device-name {
      font-size: 24px !important;
    }

    .device-specs {
      font-size: 15px;
    }

    .device-sn {
      font-size: 19px;
      display: inline !important;
      color: #fff;
      font-weight: 500;
      // max-width: 250px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  .active-device {
    background-color: #535355;
  }

  .dashboard-device:hover {
    background-color: #535355;
  }

  .dashboard-device:first-child {
    border-top-left-radius: 10px;
  }

  .dashboard-device:last-child {
    margin-right: 0;
  }
}

.scroll-btn {
  padding: 7px;
  border-radius: 50%;
  background-color: #dc2f5c;
}

.btn-wrapper-left {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 5px;
  top: -50px;
  z-index: 10;
  border-radius: 5px;
  background-color: #2626263a !important;
  cursor: pointer;
}

.btn-wrapper-right {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: -50px;
  z-index: 10;
  border-radius: 5px;
  background-color: #2626263a !important;
  cursor: pointer;
}

.setting-btn,
.chart-btn {
  cursor: pointer;
}

.setting-btn:hover,
.chart-btn:hover {
  opacity: 0.8;
  transition: 0.3s all;
}

.idle-modal-header,
.idle-modal-body,
.update-grafana-modal-header,
.update-grafana-modal-body {
  h3,
  p {
    text-align: center !important;
  }

  p {
    margin-bottom: 20px;
  }
}

.tab-title {
  h2 {
    @media screen and (max-width: 576px) {
      font-size: 15px;
    }
  }
}
